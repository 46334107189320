import React, { Component } from 'react';
import { connect } from 'react-redux';

import { kycTrigger, kycComplete } from '../actions';

import KycPerson from './KycPerson';
import KycRadio from './KycRadio';
import KycCheckbox from './KycCheckbox';
import KycText from './KycText';
import KycSelect from './KycSelect';
import KycTextArea from './KycTextArea';
import KycSelectMultiple from './KycSelectMultiple';
import imageHelp from '../img/help.svg';
import imageCheck from '../img/check.svg';

// Mapper for each question type to a component type
const componentTypes = {
    Person: KycPerson,
    Radio: KycRadio,
    Select: KycSelect,
    Text: KycText,
    Checkbox: KycCheckbox,
    TextArea: KycTextArea,
    SelectMultiple: KycSelectMultiple,
};

// Creates correct component and based on input type
const KycInput = (props) => {
    const { componentName, inputObj } = props;
    const TagName = componentTypes[componentName] || componentTypes['Person'];

    return <TagName inputObj={inputObj} />;
};

// Finds all questions and follow-up questions recursively.
const RecKyc = (props) => {
    const { input } = props;
    const toggleActiveHint = (event) => {
        event.preventDefault();
        document.querySelectorAll('.kyc__hint').forEach((el) => {
            if (event.target.parentElement !== el) {
                el.classList.remove('kyc__hint--active');
            }
        });

        event.target.parentElement.classList.toggle('kyc__hint--active');
    };
    return (
        <div className="kyc__wrapper">
            <KycInput componentName={input.meta.model.type} inputObj={input}></KycInput>
            {input.meta.options && input.meta.options.length > 0
                ? input.meta.options.map((option) => {
                      let answerIds = [];
                      if (input.answer && input.answer.length > 0) {
                          input.answer.map((answer) => {
                              answerIds.push(answer.id);
                              return answer;
                          });
                          answerIds.push();
                      }
                      if (option.questions) {
                          return option.questions.map((q, i) => {
                              let showFollowUp = false;
                              if (answerIds.indexOf(option.id) > -1 && !showFollowUp) {
                                  showFollowUp = true;
                              }
                              return option.isChosen || showFollowUp ? <RecKyc componentName={q.meta.model.type} key={q.id} input={q} /> : null;
                          });
                      } else {
                          return null;
                      }
                  })
                : null}
            {input.bodyText ? (
                <div className="kyc__hint">
                    <button onClick={toggleActiveHint.bind(this)} className="kyc__hint-trigger" type="button">
                        <img src={imageHelp} alt="frågetecken" className="kyc__hint-icon" />
                    </button>
                    <div className="kyc__hint-text">{input.bodyText}</div>
                </div>
            ) : null}
        </div>
    );
};

// Renders all questions in a KYC set recursively
class Kyc extends Component {
    constructor(props) {
        super(props);
        if (!this.props.kyc.isLoading && this.props.kyc.questions && this.props.kyc.questions.length === 0) {
            props.kycTrigger();
        }

        props.tasks.tasks.map((task) => {
            if (task.type === 'Kyc' && !task.completed) {
                let eventName = `${task.type}Started`;
                if (window.dataLayer.findIndex((d) => d.event === eventName) === -1) {
                    let dataLayerObj = {
                        event: eventName,
                    };
                    window.dataLayer.push(dataLayerObj);
                }
            }
            return task;
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.kycComplete(this.props.kyc);
    }

    render() {
        let { kyc } = this.props;
        return (
            <div className="content__body">
                {kyc.completed ? (
                    <React.Fragment>
                        <img src={imageCheck} alt="En bock" className="confirmation-icon" />
                        <h1 className="title title--h1">Tack för dina svar</h1>
                    </React.Fragment>
                ) : (
                    <h1 className="title title--h1">Vi behöver inhämta information om er</h1>
                )}                
                <p className="paragraph">
                För att vi på Factoringgruppen ska kunna erbjuda er högsta möjliga service och ett smidigt samarbete, behöver vi inhämta information om er verksamhet. Detta är både ett lagkrav och en del av vårt löpande arbete för att säkerställa en trygg och effektiv tjänst.
                </p>
                <form className="kyc" autoComplete="false">
                    {kyc.questions ? <h3 className="title title--h3 title--spacer">Kontrollfrågor</h3> : null}
                    {kyc.questions
                        ? kyc.questions.map((input, index) => {
                              return <RecKyc componentName={input.meta.model.type} key={input.id} input={input} />;
                          })
                        : null}
                    {kyc.completed ? (
                        <div className="kyc__confirmation">
                            <img src={imageCheck} alt="En bock" className="confirmation-icon" />
                            <div className="kyc__confirmation-content">
                                <h3 className="title title--h3">Tack för dina svar</h3>
                                <p className="paragraph">
                                    Nu är alla kontrollfrågor besvarade och inskickade. Vi kommer att ta en titt på era svar och kan eventuellt höra av oss till
                                    er om vi ser något som behöver förtydligas. Tack så mycket för att ni tog er tid att svara på frågorna.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <button className="button button--full button--action button--spacer-top" type="submit" onClick={this.handleSubmit.bind(this)}>
                            Skicka
                        </button>
                    )}
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginInformation: state.loginInformation,
        kyc: state.kyc,
        tasks: state.tasks,
    };
};

Kyc = connect(mapStateToProps, {
    kycTrigger,
    kycComplete,
})(Kyc);

export default Kyc;
